import axios from 'axios'
// 指定默认地址
const baseURL = window.baseApi;
axios.defaults.baseURL = baseURL;
axios.defaults.timeout = 30000;

// 指定请求拦截器
axios.interceptors.request.use(
    config => {
        if (localStorage["token"]) {
            config.headers.authorization = "bearer " + localStorage["token"]
        }
        return config;
    },
    // err => {
    //     console.log()
    //     return Promise.reject("err");
    // }
);

// 指定响应拦截器
axios.interceptors.response.use(
    response =>{
        console.log(response, "xing");
        if(response.headers.authorization){
            localStorage["token"] = response.headers.authorization;
        }
        if (response.status == 200) {
            // 更新token
           
            return response.data;
        }
        else{
            localStorage.clear("token")
            window.location.href = "/login"
        }
        return response;
    },
    err => {
        let status = err.response.status;
        console.log(err,"err");
        // if(!status) return {message: err.response.statusText, code: status}
        switch(status){
            case 401:
            case 402:
                localStorage.clear("token")
                window.location.href = "/login"
                break;   
            case 500:
                // window.alert("系统内部错误");
                return {message: err.response.statusText, code: status}
                // break;
        }
    }
) 
const requestGet = function (url, config){
    return axios.get(url, config)
}
const requestPost = function (url,data,config){
   return axios.post(url,data,config)
}
const requestPut = function (url, data, config){
    console.log("httpinnser:", data);
    return axios.put(url, data, config);
}
const requestDelete = function(url,config){
    return axios.delete(url, config);
}
let http = {
    baseURL,
    get: requestGet,
    post: requestPost,
    put: requestPut,
    delete: requestDelete,
}
// const install = function(Vue){
//     console.log(Vue,'v')
//     Vue.componet('$http',http)
//     // vue.config.$http = http;
// }
export default http;