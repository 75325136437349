<template>
    <a-modal v-model="isShow" :title="title" width="800px" @cancel="cancel" >
        
        <p>源文本</p>
        <a-input v-model="text" type="textarea" />
        <p>结果</p>
        <a-input v-model="result" id="input" type="textarea"  />
        <template slot="footer">
            <a-button  type="primary" @click="handleOk(url)">确定</a-button>
        </template>
    </a-modal>
</template>

<script>
import axios from "@/lib/axios";
export default {
    name: "popModel",
    props:{
        visible: Boolean,
        title: String,
        url:String
    },
    data(){
        return {
            isShow:false,
            text:'',
            result:''
        }
    },
    methods:{
      close(){
        this.isShow = false
      },
      handleOk(url){
        console.log(axios, "fdsa")
        axios.get(`${url }?text=${this.text}`)
        .then(res =>{
            console.log(res)
            this.result = res
            var input = document.getElementById("input");
                input.value = res;
                input.select();//选中文本
                document.execCommand("copy");

        })
      },
      cancel(){
        this.text = '',
        this.result = ''
      }
    },
    mounted(){
        this.isShow = this.visible
    }
}
</script>

<style lang="scss" scoped></style>