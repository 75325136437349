<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div class="home">
      <div class="layer1"></div>
      <div class="layer2"></div>
      <div class="layer3"></div>
      <div class="layer4"></div>
      <div class="layer5"></div>
      <div class="container">
        <div :class="[{'startedHeader': isStart},'header']">
          <!-- <img class="avatar" src="./assets/beicx.png" alt="点击开始" > -->
          <span class="title">北辰星-beicx</span>
        </div>
        <div class="items">
          <a href="javascript:void(0)" @click="start()" title="点击开始">
            <img class="start" src="./assets/start.png">
          </a>
        </div>
      </div>
    </div>
    <div :class="[{'started':isStart},'page']">
      <div class="items">
          <div class="item-title">
            <p>字段转换</p>
          </div>
            <a-anchor>
              <div class="items-content">
                <!-- <a-anchor-link title="驼峰转换" /> -->
                <p class="subTitle">驼峰转换</p>
                ：
                <p @click="open(config.ToUnderline.name, config.ToUnderline.url)" >{{config.ToUnderline.name}}</p>
                <p @click="open(config.ToBigHump.name, config.ToBigHump.url)" >{{config.ToBigHump.name}}</p>
                <p @click="open(config.ToLittleHump.name, config.ToLittleHump.url)" >{{config.ToLittleHump.name}}</p>
              </div>
            </a-anchor>
            <a-anchor>
              <div class="items-content">
                <!-- <a-anchor-link title="JSON转换" />： -->
                <p class="subTitle">JSON转换</p>
                ：
                <a-anchor-link href="#components-anchor-demo-basic" title="Json解析" />
                <a-anchor-link href="#components-anchor-demo-basic" title="Json转Model" />
                <a-anchor-link href="#components-anchor-demo-basic" title="Model转Json" />
              </div>
            </a-anchor>
            <div class="item-title">
            <p>加密解密</p>
          </div>
            <a-anchor>
              <div class="items-content">
                <!-- <a-anchor-link title="双向加密" />： -->
                <p class="subTitle">双向加密</p>
                ：
                <p @click="open(config.DescEncrypt.name, config.DescEncrypt.url)" >{{config.DescEncrypt.name}}</p>
                <p @click="open(config.DescDecrypt.name, config.DescDecrypt.url)" >{{config.DescDecrypt.name}}</p>
              </div>
            </a-anchor>
            <a-anchor>
              <div class="items-content">
                <!-- <a-anchor-link title="单向加密" />： -->
                <p class="subTitle">单向加密</p>
                ：
                <p @click="open(config.ToMD5.name, config.ToMD5.url)" >{{config.ToMD5.name}}</p>
              </div>
            </a-anchor>
      </div>
    </div>
    <div class="bottom">
      <div>
        <a class="jgawb" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010702042732" target="_blank"> &nbsp;川公网安备 51010702042732号</a>
      </div>
      <div>
        <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2023040026号-1</a>
      </div>
    </div>
    <Pop ref="Pop" :visible="visible" :title="name" :url="url"></Pop>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Pop from '@/components/Pop'
export default {
  name: 'App',
  components: {
    // HelloWorld
    Pop
  },
  data(){
    return{
      // 是否启动
      name: '',
      visible: false,
      isStart: false,
      url:'',
      config:{
        ToUnderline:{
          name:'下划线',
          url:'/api/Text/ToUnderline'
        },
        ToBigHump:{
          name:'大驼峰',
          url:'/api/Text/ToBigHump'
        },
        ToLittleHump:{
          name:'小驼峰',
          url:'/api/Text/ToLittleHump'
        },
        ToMD5:{
          name:'MD5',
          url:'/api/Security/ToMD5'
        },
        DescEncrypt:{
          name:'Desc加密',
          url:'/api/Security/DescEncrypt'
        },
        DescDecrypt:{
          name:'Desc解密',
          url:'/api/Security/DescDecrypt'
        },
      }
    }
  },
  methods:{
    start(){
      this.isStart = true;
    },
    open(name, url){
      this.name = name
      this.url = url
      this.visible = true
      this.$refs.Pop.isShow = true
    }
  },
  mounted:{
  }
}
</script>

<style>
*{
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  outline: none;
  color:rgba(3, 3, 2, 0.8);
  /* opacity: 0.8; */
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
<style lang="scss">
// 星颜色
$starColor: #fff;
// 画出阴影
@function getShadows($n){
   $shadows: '#{random(100)}vw #{random(100)}vh #{$starColor}';
   @for $i from 2 through $n{
     $shadows: '#{$shadows},#{random(100)}vw #{random(100)}vh #{$starColor}';
   }
   @return unquote($shadows);
}
// 动画效果
@keyframes moveup{
  to{
    transform: translateY(-100vh);
  }
}
// 名称闪烁
@keyframes blink{
  to{
    color:azure
  }
}
// 显示内容
@keyframes upPage{
  from{
    top:100vh;
  }
  to{
    top:18vh;
  }
}
@keyframes upTitle{
  to{
    height:15vh;
  }
}
#app{
  overflow: hidden;
}
.home{
  height: calc(100vh - 20px);
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  overflow: hidden;
  position: relative;
  left: 0;
  right: 0;
  .container{
    position: relative;
    left: 0;
    right: 0;
    .header{
      height:30vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .avatar{
        border-radius: 50%;
        height:120px;
        width:120px;
      }
      .title{
        text-align: center;
        font-family: 'lato', sans-serif;
        font-weight: 300;
        font-size:50px;
        letter-spacing: 10px;
        background: linear-gradient(white,#38495a);
        background-clip: text;
        -webkit-background-clip: text;
        color:transparent;
      }
    .title:hover{
      cursor: pointer;
      animation: blink 1s linear infinite alternate-reverse;
    }
    }
    .items{
      display: flex;
      justify-content: center;
      align-items:center;
      height:70vh;
      .start{
        height:80px;
        width:80px;
        border-radius: 50%;
        opacity: 0.5;
      }
      .start:hover{
        opacity: 1;
        box-shadow: 0px 0px 12px #ddd;
        background: #eee;
      }
    }
  }
  $duration: 5000s;
  $count: 500;
  @for $i from 1 through 5{
    .layer#{$i}{
      $duration: floor($duration / 2);
      $count: floor($count / 2);
      $size: #{$i}px;
      position: fixed;
      width: $size;
      height: $size;
      border-radius: 50%;
      background-color:$starColor;
      box-shadow: getShadows($count);
      animation: moveup $duration linear infinite alternate-reverse;
      // animation-duration:alternate-reverse;
      &::after{
        content: '';
        position: fixed;
        width: inherit;
        height: inherit;
        left: 0;
        top: 100vh;
        border-radius: inherit;
        box-shadow:inherit;
      }
    }
  }
}
.page{
    position: fixed;
    color: #000;
    height: 88vh;
    width: 100vw;
    background-color: #efefef;
    .items{
      position: absolute;
      width: 100%;
      height:100%;
      // display: flex;
      // justify-content: center;
      align-items: center;
      background: #fff;
      .item-title{
        display: flex;
        align-items: center;
        padding-left: 20px;
        p{
          font-size: 16px;
          font-weight: 600;
          width: 100vw;
          text-align: left;
        }
      }
      .items-content{
        // width: 300px;
        margin-left: 25px;
        padding-left: 25px;
        display: flex;
        // align-items: center;
        p {
          padding-left: 15px;
          cursor: pointer;
        }
        .subTitle{
          user-select: none;
          margin: 5px 0px;
          font-size: 12;
          font-weight: bold;
        }
      }

    }
  }
.started{
  animation: upPage 0.2s ease-in;
  animation-fill-mode: forwards;
}
.startedHeader{
  animation: upTitle 0.5s ease-in;
  animation-fill-mode: forwards;
}
.bottom{
  position: relative;
  height: 20px;
  bottom: -5;
  background-color: #666;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  div{
    padding: 0 10px;
    height: 20px;
  }
  a{
    color: #dedede;
  }
  a:hover{
    color: #ffffff;
  }
}
</style>

